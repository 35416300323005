<script setup>
import axios from 'axios';

//const buttons = [1, 2, 3, 4];
</script>
<template>
    <div v-if="error" class="w-full text-center p-5 text-xl">{{ error }}</div>
    <div v-else>
    <div class="kanji">{{ selectedKanji }}</div>
    <small>Kanji number {{ currentQuestionNumber }} of {{ questionCount }}</small>
    <p>{{ quizText }}</p>
    <button 
    v-for="(kanji, index) in suffeledKanji" 
    :key="index" @click="select_answer(kanji.isCorrect)"
    :class="{correct: revealCorrect === true && kanji.isCorrect, incorrect: revealCorrect === true && !kanji.isCorrect}" 
    class="quiz-button">{{ kanji.meaning }}</button>
    <button v-if="revealCorrect" class="continue" @click="fetchKanji">Continue</button>
</div>
    <button @click="restart" class="restart">Restart</button>

</template>


<script>
export default {
 emits: ["restartGame", "startGame"],
  data() {
    return {
        kanji: [], // from api
        selectedKanji: "", // from api
        suffeledKanji: [],
        quizText: "What is the english meaning of this kanji?",
        revealCorrect: false,
        questionCount: parseInt(localStorage.getItem("questionCount"), 10),
        currentQuestionNumber: parseInt(localStorage.getItem("currentQuestionNumber"), 10),
        error: "",
    };
  },
  methods: {
    async fetchKanji() {
        this.currentQuestionNumber = parseInt(localStorage.getItem("currentQuestionNumber"), 10) + 1;
        if (this.currentQuestionNumber > this.questionCount) {
            this.$emit("endGame");
            return;
        }
        localStorage.setItem("currentQuestionNumber", this.currentQuestionNumber);
        let data
        try {
            data = await axios.get('https://api.der.se/kanji');
        } catch (error) {
            this.displayError("Failed to connect to backend");
            return;
        }
        this.kanji = data.data;
        this.revealCorrect = false;

        this.correctKanji = this.getRandomKanji();
        this.selectedKanji = this.correctKanji.kanji;
        this.correctKanji.isCorrect = true;

        const alternatives = this.kanji.filter(kanji => kanji.kanji !== this.correctKanji.kanji);
        
        alternatives.forEach(kanji => kanji.isCorrect = false);

        this.suffeledKanji = this.shuffleArray([this.correctKanji, ...alternatives]);

        localStorage.setItem("currentQuestion", JSON.stringify(this.suffeledKanji));
        localStorage.setItem("answered", 0);
    },
    getRandomKanji() {
        return this.kanji[Math.floor(Math.random() * this.kanji.length)];
    },
    displayError(message) {
        this.error =message;
    },
    shuffleArray(array) {
      // Shuffle array using the Fisher-Yates algorithm
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    },
    restart() {
      this.$emit("restartGame");
    },
    select_answer(isCorrect) {
        const answered = parseInt(localStorage.getItem("answered"), 10) || 0;
        if (answered === 1) {
            return;
        }
        if (isCorrect) {
            const correctAnswers = parseInt(localStorage.getItem("correctAnswers"), 10) || 0;
            localStorage.setItem("correctAnswers", correctAnswers + 1);
        } else {
            const incorrectAnswers = parseInt(localStorage.getItem("incorrectAnswers"), 10) || 0;
            localStorage.setItem("incorrectAnswers", incorrectAnswers + 1);
        }
        this.revealCorrect = true;
        localStorage.setItem("answered", 1);
    }
  },
    mounted() {
        const answered = localStorage.getItem("answered");
 

        const currentQuestion = JSON.parse(localStorage.getItem("currentQuestion"));

        if (answered == 1 || currentQuestion == null) {
            this.fetchKanji();
        } else {
            for (let i = 0; i < currentQuestion.length; i++) {
                if (currentQuestion[i].isCorrect) {
                    this.selectedKanji = currentQuestion[i].kanji;
                    break;
                }
            }
            if (answered == 1) {
                this.revealCorrect = true;
            }
            this.suffeledKanji = currentQuestion;
        }
        
    }
};
</script>

<style scoped>
p {
    @apply w-full text-center mb-10;
}
.kanji {
    @apply text-9xl flex justify-center font-light;
}
.quiz-button {
    @apply p-2 mb-6 bg-bk-dark text-primary rounded-md w-full hover:bg-secondary ease-in-out duration-500 select-none;
}
.restart {
    @apply w-full border-bk-red border-4 hover:bg-bk-red ease-in-out duration-300 text-primary p-2 rounded-md mt-10;
}
.correct {
    @apply bg-green-600 hover:bg-green-600;
}
.incorrect {
    @apply bg-bk-red hover:bg-bk-red;
}
.continue {
    @apply w-full bg-green-600 hover:bg-green-500 ease-in-out duration-500 text-primary p-2 rounded-md mt-10;
}
small {
    @apply text-center block mb-10;
}
</style>