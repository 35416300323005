<script setup>
const amounts = [10, 20, 30, 40];
</script>
<template>
            <div class="heading">
            <div class="kanji"><div class="furigana">かん</div>漢</div>
            <div class="kanji"><div class="furigana">じ</div>字</div>
        </div>
        <p>Welcome to バカ-kanji! Practice your English understanding of various Japanese kanji characters.</p>
        <p>Select the number of characters you want to practice below, then click "Start" to begin.</p>
        <div>
        <div class="amount-buttons">
            <button v-for="amount in amounts" :key="amount" @click="select_amount(amount)" :class="{selected: selectedNumber === amount}" class="amount">{{ amount }}</button>
        </div>
        <button class="start" @click="startGame">Start</button>
    </div>
</template>

<script>
export default {
    emits: ['startGame'],
    data() {
        return {
            selectedNumber: 10,
        }
    },

    methods: {
        select_amount(number) {
            this.selectedNumber = number;
        },
        startGame() {
            this.$emit('startGame', this.selectedNumber);
        }
    }
}
</script>

<style scoped>
.start {
    @apply w-full p-2 mt-4 bg-bk-dark text-primary rounded-md hover:bg-secondary ease-in-out duration-500;
}
.heading {
    @apply text-9xl flex justify-center mb-5 font-light;

}
.amount-buttons {
    @apply flex justify-center mt-5;
}
.amount {
    @apply p-2 m-2 bg-bk-dark text-primary rounded-md flex-1 hover:bg-secondary ease-in-out duration-500;
}
.selected {
    @apply bg-green-600 hover:bg-green-500 ease-in-out duration-500;
}
.furigana
{
    @apply text-xl flex justify-center;
}
</style>

