<template>
    <p>You had {{ correctAnswers }} of {{  correctAnswers + incorrectAnswers }}</p>
    <button @click="restart" class="continue">Play Again</button>
</template>


<script>
export default {
  data() {
    return {
      correctAnswers: parseInt(localStorage.getItem("correctAnswers"), 10) || 0,
      incorrectAnswers: parseInt(localStorage.getItem("incorrectAnswers"), 10) || 0
    };
  },
  methods: {
    restart() {
      this.$emit("restartGame");
    }
  }
};
</script>

<style scoped>
p {
    @apply text-5xl w-full text-center mb-10;
}
.continue {
    @apply w-full bg-green-600 hover:bg-green-500 ease-in-out duration-500 text-primary p-2 rounded-md mt-10;
}
</style>