<script setup>
import GreetingScreen from '@/components/GreetingScreen.vue';
import QuizScreen from './components/QuizScreen.vue';
import EndScreen from './components/EndScreen.vue';
</script>
<template>
<main class="pt-2 p-2 sm:pt-10 flex justify-center">
    <div class="grow max-w-lg p-10 rounded-xl shadow-inner bg-bk-dim">
        <GreetingScreen v-if="gameState === 0" @startGame="startGame" />
        <QuizScreen v-if="gameState === 1" @endGame="endGame" @restartGame="restartGame" />
        <EndScreen v-if="gameState === 2" @restartGame="restartGame" />
    </div>
</main>
</template>

<script>
export default {
    compontents: {
        GreetingScreen,
        QuizScreen,
        EndScreen
    },
    data() {
        return {
            gameState: 0
        }
    },
    mounted()  {
        const savedState = localStorage.getItem('gameState');
        if (savedState) {
            this.gameState = parseInt(savedState, 0);
        } else {
            this.gameState = 0;
        }
    },
    methods: {
    startGame(questionCount) {
      localStorage.setItem("questionCount", questionCount);
      localStorage.setItem("currentQuestionNumber", 0);
      localStorage.setItem("correctAnswers", 0);
      localStorage.setItem("incorrectAnswers", 0);
      localStorage.setItem("answered", 1);
      this.gameState = 1;
      localStorage.setItem("gameState", this.gameState);
    },
    endGame() {
      this.gameState = 2;
      localStorage.setItem("gameState", this.gameState);
    },
    restartGame() {
      localStorage.clear();
      this.gameState = 0;
      localStorage.setItem("gameState", this.gameState);
    }
  }
};
</script>